import React from 'react'
import { useDevice } from 'device'
import loadable from '@loadable/component'

import LogoLink from 'compositions/LogoLink/LogoLink'


const Footer = loadable(() => import('compositions/Footer/Footer'))


type MinimalLayoutProps = {
  logoLink?: string
  withoutHeader?: boolean
  withFooter?: boolean
}

const MinimalLayout: React.CFC<MinimalLayoutProps> = (props) => {
  const { children, logoLink, withoutHeader, withFooter } = props

  const { isMobile } = useDevice()

  return (
    <div className="flex min-h-full flex-col">
      {
        !withoutHeader && (
          <header className={isMobile ? 'pb-8 pt-24 text-center' : 'pb-16 pt-48 text-center'}>
            <LogoLink to={logoLink} color="black" />
          </header>
        )
      }
      <main id="main" className="flex-auto">
        {children}
      </main>
      {
        withFooter && (
          <Footer />
        )
      }
    </div>
  )
}


export default MinimalLayout
